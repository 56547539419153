// Contact.js
import React from 'react';
import FeaturedClients from '../components/home/FeaturedClients';
import Footer from '../components/Footer';
import FooterActionComponent from '../components/home/FooterActionComponent';
const Client = () => {
  return (
    <div>
      <FeaturedClients></FeaturedClients>
      <FooterActionComponent></FooterActionComponent>
      <Footer></Footer>
    </div>
  );
};

export default Client;
