// Navbar.js
import React from 'react';
import { Link } from 'react-router-dom';

const FooterActionComponent = () => {
  return (
    <div className='card border-0 mt-4 mb-4'>
      <h5 className='text-center mt-5'>Want to collaborate on a future-forward project? </h5>
      <div className='row pt-3 pb-4 no-gutters' >
        <div className='col-md-6 offset-md-3 px-5 py-4 rounded' style={{ background: '#E8E8E8' }}>
          <p>Whatever it is, be it performance marketing, product development or a creative project, understanding your objectives is always our first step. Let’s get on that call and begin planning?</p>
          <button className='btn btn-sm'>
            <Link to="/contact" style={{ textDecoration: 'none' }}>
              Send Message
            </Link>
          </button>
          <a className='btn btn-sm ml-2' href='tel:+959 449 493 357'>
            Call Now
          </a>
        </div>
      </div>
    </div>
  );
};

export default FooterActionComponent;
