import React from 'react';
import { Link } from 'react-router-dom';
const HeroComponent = () => {
    const heroStyle = {
        background: `linear-gradient(
      rgba(0, 0, 0, 0.8),
      rgba(0, 0, 0, 0.2)
    ), url(${process.env.PUBLIC_URL + "images/hero.svg"}), url(${process.env.PUBLIC_URL + "images/dark-light.svg"})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        minHeight: '100vh',
        padding: '20px',
        backgroundAttachment: 'fixed'
    };

    return (
        <div style={heroStyle}>
            <div className='row mt-5 no-gutters'>
                <div className="col-md-8 offset-md-2 col-12 mt-md-5 text-white" style={{ position: 'fixed' }}>

                    <h5 className='hero-text animate__animated animate__fadeIn'>INNOSCRIPT IS <br />
                        A SOFTWARE DEVELOPMENT COMPANY
                    </h5>
                    <h5 className='mt-4 font-weight-normal animate__animated animate__fadeInUp mr-3' >that specializes in providing IT solutions, high
                        load enterprise system, web app development,
                        mobile app development, desktop app
                        development and Internet of Things (IoT)
                        development to business organizations on every
                        scale. </h5>
                    <button className='btn btn-sm bg-dark  border-0  mt-1'>
                        <Link to="/contact"  style={{ textDecoration: 'none',color:'white' }}>
                            Get in Touch
                        </Link>
                    </button>
                    <button className='btn btn-sm btn-dark ml-2 mt-1'>
                        <Link to="/about" style={{ textDecoration: 'none',color:'white' }}>
                            About Us
                        </Link>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default HeroComponent;
