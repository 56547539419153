// Contact.js
import React from 'react';

const Portfolio = () => {
  return (
    <div>
      <h1>Portfolio Page</h1>
      {/* Add your contact form or content here */}
    </div>
  );
};

export default Portfolio;
