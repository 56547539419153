// Navbar.js
import React from 'react';
import { Zoom } from 'react-awesome-reveal';
import { Link } from 'react-router-dom';

const Services = () => {
    return (
        <div className='row no-gutters'>
            <div className='col-md-8 offset-md-2 mt-5 row no-gutters'>
                <div className='col-12 text-center mt-5 mb-5 border-bottom'><h2>Services</h2></div>
                <div className='col-md-6'>
                    <Zoom>
                        <h1 className='text-center'>
                            <img src={process.env.PUBLIC_URL + "images/dc.png"} className="rounded " style={{ width: '100px', height: 'auto' }} />
                        </h1>
                    </Zoom>
                </div>
                <div className='col-md-6'>
                    <Zoom>
                        <h4 className='text-center'>
                            Dedicated Development
                        </h4>
                        <p className='pt-2 px-3'>
                            Use our dedicated development teams to rapidly
                            scale up or down your development resources.
                        </p>
                    </Zoom>
                </div>
                <div className='col-md-6 mt-4'>
                    <Zoom>
                        <h1 className='text-center'>
                            <img src={process.env.PUBLIC_URL + "images/cl.png"} className="rounded " style={{ width: '100px', height: 'auto' }} />
                        </h1>
                    </Zoom>
                </div>
                <div className='col-md-6 mt-4'>
                    <Zoom>
                        <h4 className='text-center'>
                            Cloud Services & Data Management
                        </h4>
                        <p className='pt-2 px-3'>
                            We will work with you to establish the

                            appropriate cloud solution for your business
                        </p>
                    </Zoom>
                </div>
                <div className='col-md-6 mt-4'>
                    <Zoom>
                        <h1 className='text-center'>
                            <img src={process.env.PUBLIC_URL + "images/code.png"} className="rounded " style={{ width: '100px', height: 'auto' }} />
                        </h1>
                    </Zoom>
                </div>
                <div className='col-md-6 mt-4'>
                    <Zoom>
                        <h4 className='text-center'>
                            Custom Software Development
                        </h4>
                        <p className='pt-2 px-3'>
                            Across any flavor of data we can help you define,

                            measure and act on your business.
                        </p>
                    </Zoom>
                </div>
            </div>
        </div>
    );
};

export default Services;
