// Contact.js
import React from 'react';
import Footer from '../components/Footer';
import FeaturedClients from '../components/home/FeaturedClients';
import Team from '../components/Team';
import Services from '../components/Services';
const About = () => {
	return (
		<>
			<div className='row no-gutters'>
				<div className='col-md-6  mt-5 px-3 px-md-5'>
					<h2>ABOUT US</h2>
					<p className='mt-5' style={{ lineHeight: '30px' }}>
						InnoScript is a software development company
						that specializes in providing IT solutions, high
						load enterprise system, web app development,
						mobile app development, desktop app
						development and Internet of Things (IoT)
						development to business organizations on every
						scale. Our Company has provided services to a
						wide range of companies including Coca-cola.
					</p>
				</div>
				<div className='col-md-6 mt-5 px-3 px-md-5'>
					<div className='mt-5 border'>
						<img src={process.env.PUBLIC_URL + "images/reg.png"} className="rounded " style={{ width: '100%', height: 'auto' }} />
					</div>
				</div>
			</div>
			<Services />
			<Team />
			<FeaturedClients />
			<br /><br />
			<Footer />
		</>
	);
};

export default About;
