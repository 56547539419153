
import React from 'react';

const MissionVisionComponent = () => {
  return (
    <div className="card border-0 bg-white">
      <div className='row mt-5 no-gutters'>
        <div className="col-md-10 offset-md-1 row bg-white no-gutters mt-md-2">
          <div className='col-md-6 px-md-5'>
            <div className='card border-0 px-md-5 px-4 py-3 py-md-4'>
              <h6 className='text-center'>
                <img src={process.env.PUBLIC_URL + "images/target.png"} style={{ width: '50px', height: '50px' }} />
              </h6>
              <h4>Mission</h4>
              <p className='mt-2'>
                To provide IT support for all business and change digitize environment with innovative
                technologies.
              </p>
            </div>
          </div>
          <div className='col-md-6 px-md-5'>
            <div className='card border-0 px-md-5 px-4 py-md-3 py-4'>
              <h6 className='text-center'>
                <img src={process.env.PUBLIC_URL + "images/vision.png"} style={{ width: '50px', height: '50px' }} />
              </h6>
              <h4>Vision</h4>
              <p className='mt-2'>
                In order to have long-term cooperation with valuable customers and high product
                quality standards.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MissionVisionComponent;
