
import React from 'react';
import { Zoom } from "react-awesome-reveal";

const FeaturedClients = () => {
    return (
        <div className="card border-0 bg-white">
            <div className="row bg-white no-gutters mt-md-2">
                <h3 className='col-md-10 offset-md-1 mt-5 border-bottom pb-1 px-2 '>Featured Clients </h3>
                <div className="col-12 row pb-5 no-gutters">
                    <div className="col-md-10 offset-md-1 row no-gutters">
                        <div className='col-md-4 mt-4  border-0 '>
                            <Zoom>
                                <div className='card  mx-3 px-md-4 border-0'>
                                    <div className="mt-2 p-0" style={{
                                        backgroundImage: `url(${process.env.PUBLIC_URL}images/clients/co.png)`,
                                        backgroundSize: 'contain',
                                        backgroundRepeat: 'no-repeat',
                                        backgroundPosition: 'center',
                                        height: '170px'
                                    }}></div>
                                </div>
                            </Zoom>
                        </div>
                        <div className='col-md-4 mt-4  border-0 '>
                            <Zoom>
                                <div className='card  mx-3 px-md-4 border-0'>
                                    <div className="mt-2 p-0" style={{
                                        backgroundImage: `url(${process.env.PUBLIC_URL}images/clients/tr.png)`,
                                        backgroundSize: 'contain',
                                        backgroundRepeat: 'no-repeat',
                                        backgroundPosition: 'center',
                                        height: '170px'
                                    }}></div>
                                </div>
                            </Zoom>
                        </div>
                        <div className='col-md-4 mt-4  border-0 '>
                            <Zoom>
                                <div className='card  mx-3 px-md-4 border-0'>
                                    <div className="mt-2 p-0" style={{
                                        backgroundImage: `url(${process.env.PUBLIC_URL}images/clients/ph.png)`,
                                        backgroundSize: 'contain',
                                        backgroundRepeat: 'no-repeat',
                                        backgroundPosition: 'center',
                                        height: '170px'
                                    }}></div>
                                </div>
                            </Zoom>
                        </div>
                        <div className='col-md-4 mt-4  border-0 '>
                            <Zoom>
                                <div className='card  mx-3 px-md-4 border-0'>
                                    <div className="mt-2 p-0" style={{
                                        backgroundImage: `url(${process.env.PUBLIC_URL}images/clients/q.png)`,
                                        backgroundSize: 'contain',
                                        backgroundRepeat: 'no-repeat',
                                        backgroundPosition: 'center',
                                        height: '170px'
                                    }}></div>
                                </div>
                            </Zoom>
                        </div>
                        <div className='col-md-4 mt-4  border-0 '>
                            <Zoom>
                                <div className='card  mx-3 px-md-4 border-0'>
                                    <div className="mt-2 p-0" style={{
                                        backgroundImage: `url(${process.env.PUBLIC_URL}images/clients/arg.png)`,
                                        backgroundSize: 'contain',
                                        backgroundRepeat: 'no-repeat',
                                        backgroundPosition: 'center',
                                        height: '170px'
                                    }}></div>
                                </div>
                            </Zoom>
                        </div>
                        <div className='col-md-4 mt-4  border-0 '>
                            <Zoom>
                                <div className='card  mx-3 px-md-4 border-0'>
                                    <div className="mt-2 p-0" style={{
                                        backgroundImage: `url(${process.env.PUBLIC_URL}images/clients/p.png)`,
                                        backgroundSize: 'contain',
                                        backgroundRepeat: 'no-repeat',
                                        backgroundPosition: 'center',
                                        height: '170px'
                                    }}></div>
                                </div>
                            </Zoom>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FeaturedClients;
