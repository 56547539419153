// Navbar.js
import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <div style={{ background: '#D9D9D9' }} className="pt-4">
      <div className='row pt-3 pb-4 no-gutters'>
        <div className='col-md-11 offset-md-1'>
          <strong> Contact to us : 	admin@innoscript.co   |  +959 449 494 457 </strong>
          <p>© 2017-2024 All rights reserved by INNOSCRIPT CO., LTD</p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
