// Contact.js
import React from 'react';
import Services from '../components/Services';
import Footer from '../components/Footer';
import FooterActionComponent from '../components/home/FooterActionComponent';

const Service = () => {
  return (
    <>
      <Services />
      <FooterActionComponent />
      <Footer />
    </>
  );
};

export default Service;
