
import React from 'react';

const SolutionStackComponent = () => {
    return (
        <div className="card border-0 bg-white">
            <div className="row bg-white no-gutters mt-md-2">
                <h3 className='col-md-10 offset-md-1 mt-5 border-bottom pb-1 text-center'>Our Solution Stack </h3>
                <div className="col-12 row pb-5 no-gutters">
                    <div className="col-md-10 offset-md-1 row no-gutters">
                        <div className='col-md-4 mt-4  border-0 '>
                            <div className='card bg-light mx-md-1 mx-3 px-md-4 border-0 runded px-2'>
                                <span class="text-center mt-3">
                                    <i class="bi bi-file-word-fill text-danger"></i>
                                </span>
                                <h6 className='mt-4 text-danger'>Requirement Planning and Analysis</h6>
                                <ul className='mt-1'>
                                    <li>Goals</li>
                                    <li>Features & Functionalities</li>
                                    <li>Budget & Timelines</li>
                                    <li>Resources</li>
                                </ul>
                            </div>
                        </div>
                        <div className='col-md-4 mt-4   border-0 '>
                            <div className='card bg-light mx-md-1 mx-3 px-md-4 border-0 runded px-2'>
                                <span class="text-center mt-3">
                                    <i class="bi bi-bezier text-danger"></i>
                                </span>
                                <h5 className='mt-4 text-danger'>Design and Prototype </h5>
                                <ul className='pb-3'>
                                    <li>Create a blueprint of the system</li>
                                    <li>Overall Architecture and Components</li>
                                    <li>Specify the details of each component</li>
                                </ul>
                            </div>
                        </div>
                        <div className='col-md-4 mt-4  '>
                            <div className='card bg-light mx-md-1 mx-3 px-md-4 border-0 runded px-2'>
                                <span class="text-center mt-3">
                                    <i class="bi bi-code-square text-danger"></i>
                                </span>
                                <h5 className='mt-4 text-danger'>Implementation (Coding)</h5>
                                <ul className='pb-3'>
                                    <li>UI/UX designers</li>
                                    <li>Front-end developers</li>
                                    <li>Back-end developers</li>
                                </ul>
                            </div>
                        </div>
                        <div className='col-md-4 mt-4  '>
                            <div className='card bg-light mx-md-1 mx-3 px-md-4 border-0 runded px-2'>
                                <span class="text-center mt-3 text-danger">
                                    <i class="bi bi-check-circle-fill"></i>
                                </span>
                                <h5 className='mt-4 text-danger'>Testing</h5>
                                <ul>
                                    <li>Unit Testing</li>
                                    <li>Integration Testing</li>
                                    <li>System Testing</li>
                                    <li>UAT</li>
                                </ul>
                            </div>
                        </div>
                        <div className='col-md-4 mt-4  '>
                            <div className='card bg-light mx-md-1 mx-3 px-md-4 border-0 runded px-2'>
                                <span class="text-center mt-3 text-danger">
                                    <i class="bi bi-check-circle-fill"></i>
                                </span>
                                <h5 className='mt-4 text-danger'>Deployment</h5>
                                <ul>
                                    <li>Roll out the software to the production environment</li>
                                    <li>Provide necessary documentation</li>
                                    <li>Training for end-users</li>
                                </ul>
                            </div>
                        </div>
                        <div className='col-md-4 mt-4  '>
                            <div className='card bg-light mx-md-1 mx-3 px-md-4 border-0 runded px-2'>
                                <span class="text-center mt-3">
                                    <i class="bi bi-chevron-bar-expand text-danger"></i>
                                </span>
                                <h5 className='mt-4 text-danger'>Maintenance and Support</h5>
                                <ul>
                                    <li>Bug Identification</li>
                                    <li>Bug Reporting</li>
                                    <li>Bug Fixing</li>
                                    <li>Release</li>
                                </ul>
                            </div>
                        </div>
                        <div className='col-md-6 offset-md-3 mt-4  '>
                            <div className='card bg-light mx-md-1 mx-3 px-md-4 border-0 runded px-2'>
                                <span class="text-center mt-3">
                                    <i class="bi bi-arrow-repeat text-danger"></i>
                                </span>
                                <h5 className='mt-4 text-danger'>Iterative Development</h5>
                                <ul>
                                    <li>Iterative development cycles with frequent feedback and adjustments.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};
export default SolutionStackComponent;
