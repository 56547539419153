import React, { useState, useEffect } from 'react';
import { Zoom } from 'react-awesome-reveal';

const Team = () => {
  const [teamData, setTeamData] = useState([]);

  useEffect(() => {
    const fetchTeamData = async () => {
      try {
        const response = await fetch('https://innoscript.co/api/app/v1/teams');
        const data = await response.json();
        setTeamData(data);
      } catch (error) {
        console.error('Error fetching team data:', error);
      }
    };

    fetchTeamData();
  }, []);

  return (
    <div className="pt-4 pb-5  mt-3 bg-light mt-5">
      <h4 className='text-center mt-2 '>Our Teams</h4>
      <div className='row  no-gutters'>
        <div className='col-md-10 offset-md-1'>
          <div className='row no-gutters mt-3 px-2'>
            {teamData.map((teamMember) => (
              <div key={teamMember.id} className='col-md-4 mt-md-2 mt-3'>
                <div className='py-3 row mx-1' style={{ borderRadius: '5px', background: 'linear-gradient(0deg, rgb(255, 222, 233) 0%, rgb(181, 255, 252) 100%)' }}>
                  <div className='col-6 col-md-6'>
                    <Zoom>
                      <img src={teamMember.avatar || process.env.PUBLIC_URL + "images/team/default-avatar.png"} className="rounded" style={{ width: '100%', height: 'auto' }} alt={teamMember.name} />
                    </Zoom>
                  </div>
                  <div className='col-6 col-md-6 '>
                    <Zoom>
                      <div>
                        <h6 className='mt-md-4 mt-3 text-secondary font-weight-bold'>{teamMember.name}</h6>
                        <h6 className='pt-0 mt-0 text-muted'>{teamMember.role}</h6>
                        <div className='mt-md-3 mt-2'>
                          <a className='text-info' href={`mailto:${teamMember.email}`}>
                            <i className="bi bi-envelope-fill"></i>
                          </a>
                          <a className='mx-3 text-info' href={`tel:${teamMember.phone}`}>
                            <i className="bi bi-telephone-fill"></i>
                          </a>
                          <a className='text-info' href={teamMember.web} target="_blank" rel="noopener noreferrer">
                            <i className="bi bi-browser-chrome"></i>
                          </a>
                        </div>
                      </div>
                    </Zoom>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Team;
