// Contact.js
import React from 'react';

const Blog = () => {
  return (
    <div>
      <h1>Blog Page</h1>
      {/* Add your contact form or content here */}
    </div>
  );
};

export default Blog;
