import React, { useState, useEffect } from 'react';
import HeroComponent from '../components/home/HeroComponent';
import ExperiencesComponent from '../components/home/ExperiencesComponent';
import SolutionStackComponent from '../components/home/SolutionStackComponent';
import BlogComponent from '../components/home/BlogComponent';
import FeaturedClients from '../components/home/FeaturedClients';
import Footer from '../components/Footer';
import FooterActionComponent from '../components/home/FooterActionComponent';
import MissionVisionComponent from '../components/home/MissionVisionComponent';

const Home = () => {
	const [isScrolled, setIsScrolled] = useState(false);

	const goToBottomStyle = {
		position: 'absolute',
		bottom: '0',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		display: isScrolled ? 'none' : 'block',
	};

	useEffect(() => {
		const handleScroll = () => {
			if (window.scrollY > 0) {
				setIsScrolled(true);
			} else {
				setIsScrolled(false);
			}
		};
		window.addEventListener('scroll', handleScroll);

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	return (
		<div>
			<HeroComponent />
			<span style={goToBottomStyle} className="scroll"></span>
			<MissionVisionComponent />
			<ExperiencesComponent />
			<FeaturedClients />
			<SolutionStackComponent />
			{/* <BlogComponent /> */}
			<FooterActionComponent />
			<Footer />
		</div>
	);
};

export default Home;
