// Navbar.js
import React from 'react';
import { Link } from 'react-router-dom';

const Navbar = () => {
  return (
    <div style={{ background: '#940B0B' }} className="sticky-top">
      <nav className="navbar  navbar-expand-lg pt-2">

        <Link className="navbar-brand  text-white" to="/">
          <img src={process.env.PUBLIC_URL + "logo.png"} className="rounded" style={{ width: '32px', height: 'auto' }} />
          InnoScript</Link>

        <button className="navbar-toggler border-0" type="button" data-toggle="collapse" data-target="#navbarNav" >
          <i class="bi bi-filter-right text-white"></i>
        </button>

        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="nav navbar-nav ml-auto">

            <li className="pr-4" >
              <Link to="/" style={{ textDecoration: 'none' }}>
                <a className="nav-link  text-white" href="home">Home</a>
              </Link>
            </li>

            <li className="pr-4" >
              <Link to="/about" style={{ textDecoration: 'none' }}>
                <a className="nav-link  text-white" href="home">About</a>
              </Link>
            </li>

            <li className="nav-item pr-4" >
              <Link to="/service" style={{ textDecoration: 'none' }}>
                <a className="nav-link  text-white" href="profile">Services</a>
              </Link>
            </li>

            {/* <li className="nav-item pr-4"  >
              <Link to="/portfolio" style={{ textDecoration: 'none' }}>
                <a className="nav-link  text-white" href="githubapi"> Portfolio </a>
              </Link>
            </li> */}

            <li className="nav-item pr-4"  >
              <Link to="/client" style={{ textDecoration: 'none' }}>
                <a className="nav-link  text-white" href="githubapi"> Clients </a>
              </Link>
            </li>
            {/* <li className="nav-item pr-4"  >
              <Link to="/blog" style={{ textDecoration: 'none' }}>
                <a className="nav-link  text-white" href="githubapi"> Blog </a>
              </Link>
            </li> */}
            <li className="nav-item pr-4"  >
              <Link to="/contact" style={{ textDecoration: 'none' }}>
                <a className="nav-link  text-white" href="githubapi"> Get in Touch  </a>
              </Link>
            </li>


          </ul>
        </div>

      </nav>
    </div>
  );
};

export default Navbar;
