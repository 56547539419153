

import { Slide, Fade } from "react-awesome-reveal";
import React from 'react';

const ExperiencesComponent = () => {
    return (
        <div className="card border-0 bg-white">
            <div className="row bg-white no-gutters ">
                <h3 className='pt-5 col-md-10 col-12 bg-white offset-md-1 px-2 text-center border-bottom pb-2'>Experiences We've Created </h3>
                <div className="col-12 row no-gutters pb-5 bg-light mt-2" style={{ borderRadius: '20px 20px 0 0' }}>
                    <div className="col-md-10 offset-md-1 row no-gutters px-2 mt-3">
                        <div className='col-md-4 mt-4 mt-md-4 px-md-3 text-dark '>
                            <Slide direction="up">
                                <div className="card bg-white border-0 rounded px-3" style={{ background: '#F8F9FA' }}>
                                    <h5 className="pt-2">GGI TOKIO MARINE</h5>
                                    <div style={{ borderBottom: '1px dotted red' }}></div>
                                    <div className="m-0 p-0" style={{
                                        backgroundImage: `url(${process.env.PUBLIC_URL}images/project/ggitokiomarine.png)`,
                                        backgroundSize: 'contain',
                                        backgroundRepeat: 'no-repeat',
                                        backgroundPosition: 'center',
                                        height: '170px'
                                    }}></div>
                                    <a href="https://ggitokiomarine.com/" target="_blank" className=""><i class="text-center bi bi-arrow-right"></i></a>
                                </div>
                            </Slide>
                        </div>
                        <div className='col-md-4 mt-4 mt-md-4 px-md-3 text-dark '>
                            <Slide direction="up">
                                <div className="card bg-white border-0 rounded px-3" style={{ background: '#F8F9FA' }}>
                                    <h5 className="pt-2">PINLON TRAVEL & TOUR</h5>
                                    <div style={{ borderBottom: '1px dotted red' }}></div>
                                    <div className="m-0 p-0" style={{
                                        backgroundImage: `url(${process.env.PUBLIC_URL}images/project/PINLONTRAVELTOUR.png)`,
                                        backgroundSize: 'contain',
                                        backgroundRepeat: 'no-repeat',
                                        backgroundPosition: 'center',
                                        height: '170px'
                                    }}></div>
                                    <a href="https://www.figma.com/proto/QK320iCUBI6423Lp2HfmHN/Travel-%26-Tour-Website?type=design&node-id=1-2&t=JHD6za5vwAwmqGfr-1&scaling=scale-down-width&page-id=0%3A1&mode=design" target="_blank"><i class="text-center bi bi-arrow-right"></i></a>
                                </div>
                            </Slide>
                        </div>

                        <div className='col-md-4 mt-4 mt-md-4 px-md-3 text-dark '>
                            <Slide direction="up">
                                <div className="card bg-white border-0 rounded px-3" style={{ background: '#F8F9FA' }}>
                                    <h5 className="pt-2">MYINT MO PHYU PWINT</h5>
                                    <div style={{ borderBottom: '1px dotted red' }}></div>
                                    <div className="m-0 p-0" style={{
                                        backgroundImage: `url(${process.env.PUBLIC_URL}images/project/MMPP.png)`,
                                        backgroundSize: 'contain',
                                        backgroundRepeat: 'no-repeat',
                                        backgroundPosition: 'center',
                                        height: '170px'
                                    }}></div>

                                    <a href="https://www.figma.com/proto/QK320iCUBI6423Lp2HfmHN/Travel-%26-Tour-Website?type=design&node-id=1-2&t=JHD6za5vwAwmqGfr-1&scaling=scale-down-width&page-id=0%3A1&mode=design" target="_blank"><i class="text-center bi bi-arrow-right"></i></a>
                                </div>
                            </Slide>
                        </div>
                        <div className='col-md-4 mt-4 mt-md-4 px-md-3 text-dark '>
                            <Slide direction="up">
                                <div className="card bg-white border-0 rounded px-3" style={{ background: '#F8F9FA' }}>
                                    <h5 className="pt-2">AGRITECH POS</h5>
                                    <div style={{ borderBottom: '1px dotted red' }}></div>
                                    <div className="mt-2 p-0" style={{
                                        backgroundImage: `url(${process.env.PUBLIC_URL}images/project/ARG_POS.png)`,
                                        backgroundSize: 'contain',
                                        backgroundRepeat: 'no-repeat',
                                        backgroundPosition: 'center',
                                        height: '170px'
                                    }}></div>
                                    <a href="#" target="_blank"><i class="text-center bi bi-arrow-right"></i></a>
                                </div>
                            </Slide>
                        </div>
                        <div className='col-md-4 mt-4 mt-md-4 px-md-3 text-dark '>
                            <Slide direction="up">
                                <div className="card bg-white border-0 rounded px-3" style={{ background: '#F8F9FA' }}>
                                    <h5 className="pt-2">LAUNDRY MOBILE APP</h5>
                                    <div style={{ borderBottom: '1px dotted red' }}></div>
                                    <div className="mt-3 p-0" style={{
                                        backgroundImage: `url(${process.env.PUBLIC_URL}images/project/LAU_POS.png)`,
                                        backgroundSize: 'contain',
                                        backgroundRepeat: 'no-repeat',
                                        backgroundPosition: 'center',
                                        height: '170px'
                                    }}></div>
                                    <a href="#" target="_blank" ><i class="text-center bi bi-arrow-right"></i></a>
                                </div>
                            </Slide>
                        </div>
                        <div className='col-md-4 mt-4 mt-md-4 px-md-3 text-dark '>
                            <Slide direction="up">
                                <div className="card bg-white border-0 rounded px-3" style={{ background: '#F8F9FA' }}>
                                    <h6 className="pt-2">COCA-COLA LFG SUMMIT 2023 EVENT </h6>
                                    <div style={{ borderBottom: '1px dotted red' }}></div>
                                    <div className="mt-3 p-0" style={{
                                        backgroundImage: `url(${process.env.PUBLIC_URL}images/project/CO.png)`,
                                        backgroundSize: 'contain',
                                        backgroundRepeat: 'no-repeat',
                                        backgroundPosition: 'center',
                                        height: '170px'
                                    }}></div>
                                    <a href="#"><i class="text-center bi bi-arrow-right"></i></a>
                                </div>
                            </Slide>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default ExperiencesComponent;
