import React, { useState } from 'react';
import './../Contact.css';
import Footer from '../components/Footer';
import FooterActionComponent from '../components/home/FooterActionComponent';

const ContactForm = () => {
	const URL = 'https://innoscript.co/api/app/v1/messages';
	const [formData, setFormData] = useState({
		name: '',
		email: '',
		subject: '',
		message: '',
	});

	const [formMessage, setFormMessage] = useState({
		warning: '',
		success: '',
	});

	const [isSubmitting, setIsSubmitting] = useState(false);

	const handleInputChange = (e) => {
		setFormData({
			...formData,
			[e.target.name]: e.target.value,
		});
	};

	const isFormValid = () => {
		return (
			formData.name.trim() !== '' &&
			formData.email.trim() !== '' &&
			formData.subject.trim() !== '' &&
			formData.message.trim() !== ''
		);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		if (!isFormValid()) {
			setFormMessage({
				...formMessage,
				success: '',
				warning: 'Please fill in all the required fields.',
			});
			return;
		}

		try {
			setIsSubmitting(true);
			console.log(formData);

			const response = await fetch(URL, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(formData),
			});
			if (response.ok) {
				setFormMessage({
					...formMessage,
					success: 'Your message was sent, thank you!',
					warning: '',
				});
			} else {
				setFormMessage({
					...formMessage,
					success: '',
					warning: 'Something went wrong. Please try again later.',
				});
			}
		} catch (error) {
			console.error('Error submitting form:', error);
			setFormMessage({
				...formMessage,
				success: '',
				warning: 'Something went wrong. Please try again later.',
			});
		} finally {
			setIsSubmitting(false);
		}
	};


	return (
		<section className="mt-5">
			<div className="container">
				<div className="row justify-content-center">
					<h3 className='mx-3' style={{ fontFamily: 'serif' }}>HAVE SOME QUESTIONS ? </h3>
					<div className="col-lg-10 mt-4">
						<div className="wrapper p-md-3 mb-4 rounded">
							<div className="row no-gutters ">
								<div className="col-md-6 d-flex align-items-stretch rounded-md">
									<div className="contact-wrap w-100 p-md-5 p- py-5 rounded-md">
										<h3 className="mb-4 text-center">Write us</h3>
										<div id="form-message-warning" className="mb-4">
											{formMessage.warning}
										</div>
										<div id="form-message-success" className="mb-4">
											{formMessage.success}
										</div>
										<form
											onSubmit={handleSubmit}
											id="contactForm"
											name="contactForm"
											className="contactForm"
										>
											<div className="row">
												<div className="col-md-12">
													<div className="form-group p-md-0 px-3 m-0">
														<input
															type="text"
															className="form-control form-control-sm"
															name="name"
															id="name"
															placeholder="Name"
															onChange={handleInputChange}
															value={formData.name}
														/>
													</div>
												</div>
												<div className="col-md-12">
													<div className="form-group p-md-0 px-3 m-0">
														<input
															type="email"
															className="form-control form-control-sm"
															name="email"
															id="email"
															placeholder="Email"
															onChange={handleInputChange}
															value={formData.email}
														/>
													</div>
												</div>
												<div className="col-md-12">
													<div className="form-group p-md-0 px-3 m-0">
														<input
															type="text"
															className="form-control form-control-sm"
															name="subject"
															id="subject"
															placeholder="Subject"
															onChange={handleInputChange}
															value={formData.subject}
														/>
													</div>
												</div>
												<div className="col-md-12">
													<div className="form-group p-md-0 px-3 m-0">
														<textarea
															name="message"
															className="form-control form-control-sm"
															id="message"
															rows="3"
															placeholder="Message"
															onChange={handleInputChange}
															value={formData.message}
														></textarea>
													</div>
												</div>

												<div className="col-md-12 mt-3">
													<div className="form-group p-md-0 px-3 m-0">
														<input 
														style={{background:'#f39422'}}
															type="submit"
															value="Send Message"
															className="btn btn-sm border-0 p-1 btn-primary"
															disabled={isSubmitting || !isFormValid()}
														/>
														<br/><br/>
														<span className='mt-4 text-muted'>{formMessage.warning}  {formMessage.success}</span>
													</div>
												</div>
											</div>
										</form>
									</div>
								</div>
								<div className="col-md-6 d-flex align-items-stretch">
									<div className="info-wrap w-100 p-md-5 p-4 py-5 img text-muted">
										<h3>Contact information</h3>
										<div className="dbox w-100 d-flex align-items-start mt-4">
											<div className="icon d-flex align-items-center justify-content-center">
												<i className="bi bi-geo-alt "></i>
											</div>
											<div className="text pl-3">
												<p>Address: No. (165), Middle Baho Road, Mayangone Yangon</p>
											</div>
										</div>

										<div className="dbox w-100 d-flex align-items-center">
											<div className="icon d-flex align-items-center justify-content-center">
												<i className="bi bi-telephone-fill"></i>
											</div>
											<div className="text pl-3">
												<p>Phone 	: <a href="tel://1234567920">+959 449 494 457</a></p>
											</div>  
										</div>
										<div className="dbox w-100 d-flex align-items-center">
											<div className="icon d-flex align-items-center justify-content-center">
												<i className="bi bi-envelope-at-fill"></i>
											</div>
											<div className="text pl-3">
												<p>Email : <a href="mailto:admin@innoscript.co">admin@innoscript.co</a></p>
											</div>
										</div>
										<div className="dbox w-100 d-flex align-items-center">
											<div className="icon d-flex align-items-center justify-content-center">
												<i className="bi bi-browser-chrome"></i>
											</div>
											<div className="text pl-3">
												<p>Website <a href="#"> : innoscript.co</a></p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<FooterActionComponent />
			<Footer />
		</section>
	);
};

export default ContactForm;
